<script lang="ts" setup>
import { CustomLink, LinkColor } from "@frontiers-vue3/public-pages";
import { mapToAsidePosts, mapToCardPostNew } from "~/mappers/components";
import {
  IBriefPostResponse,
  ILandingPageResponse
} from "~/types/contentful/responses";
import { useContentfulQuery, useCacheService } from "~/services";
import type { ISeo } from "~/types/contentful/models";
import { homePageLDJson } from "~/json/google-structure-data";
import {
  CardNewsMainImageQueries,
  CardNewsSecondaryImageQueries
} from "~/const/ImageQueriesByMedia";

const contentfulService = useContentfulQuery();
const config = useRuntimeConfig();

const landingPage: ILandingPageResponse =
  await contentfulService.getLandingPage();

const imagesMain = computed(() => {
  return landingPage.mainHeaderImage;
});

const imagesCollection = computed(() => {
  return landingPage.mainHeaderImagesCollection.items;
});

const getFeaturedNews = () => {
  const cardsProps = landingPage.featureNewsCollection.items.map(
    (card: IBriefPostResponse, i: number) => {
      let mappedCard;
      if (i === 0) {
        mappedCard = mapToCardPostNew(card, CardNewsMainImageQueries, false);
      } else {
        mappedCard = mapToCardPostNew(
          card,
          CardNewsSecondaryImageQueries,
          true
        );
      }
      return mappedCard;
    }
  );
  return cardsProps;
};

const getLatestPosts = async () => {
  const posts = await contentfulService.getAsidePosts({ limit: 6 });
  return mapToAsidePosts(posts, false);
};

const latestPosts = await getLatestPosts();

const categoriesWithCards = computed(
  () => landingPage.featureCategoriesCollection.items
);

const viewAllLink = {
  text: "View all news",
  url: "/news/all",
  target: "_self"
};

const seo = inject("seo") as Ref<ISeo>;

useSeo(seo.value).setPageMetaInfo();

useHead({
  link: [
    {
      rel: "alternate",
      type: "application/rss+xml",
      title: "RSS Feed for Frontiers Science News",
      href: `${config.public.baseUrl}/news/rss`
    }
  ],
  script: [
    {
      type: "application/ld+json",
      children: JSON.stringify(homePageLDJson(config))
    }
  ]
});

const { usePageCache, shortTTL } = useCacheService();
usePageCache(shortTTL);
</script>

<template>
  <HomeMainHeader
    :images-collection="imagesCollection"
    :images-main="imagesMain" />
  <div class="Layout Layout--paralax">
    <div class="Layout__body Layout__body--mainAside">
      <main class="Layout__main">
        <CardGridHome type="Primary" :cards="getFeaturedNews()" />
      </main>
      <div class="Layout__aside Layout__aside--home">
        <div class="Layout__aside__posts">
          <h2 class="Layout__aside__title">Latest posts</h2>
          <div class="Layout__aside__grid">
            <AsidePosts :posts="latestPosts" />
          </div>
          <CustomLink
            :link="viewAllLink"
            color="Maincolor"
            size="Small"
            icon="ChevronRight" />
        </div>
        <div class="Layout__aside__divider"></div>
        <div class="Layout__aside__categories">
          <h2 class="Layout__aside__title">Categories</h2>
          <CategoryCloud />
        </div>
      </div>
    </div>
    <div class="Layout__body">
      <div
        v-for="category in categoriesWithCards"
        class="Layout__section Layout__section--xl"
        :key="category.slug">
        <div class="Layout__section__header">
          <h2>{{ category.name }}</h2>
          <CustomLink
            :link="{
              text: 'View more',
              url: `/news/category/${category.slug}`
            }"
            color="Maincolor"
            size="Small"
            icon="ChevronRight" />
        </div>
        <CardGridHome type="Secondary" :cards="category.cards" />
      </div>
    </div>
    <div class="Layout__body">
      <CustomLink
        type="FlatButton"
        :link="viewAllLink"
        :color="LinkColor.MainColor" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.Layout {
  &__body {
    margin-top: var(--spacing-vertical-sm);

    & > .Link__wrapper {
      margin: var(--spacing-vertical-sm) auto 0 auto;
      text-align: center;
      display: block;

      @media #{$break-md} {
        margin-bottom: var(--spacing-vertical-xl);
      }
    }
  }

  &--paralax {
    background: #f7f7f7;
    z-index: 1;
    position: relative;
    padding-top: 1px;
  }
}
</style>
